import { render, staticRenderFns } from "./handle-category.vue?vue&type=template&id=62ad7f18&scoped=true&"
import script from "./handle-category.vue?vue&type=script&lang=js&"
export * from "./handle-category.vue?vue&type=script&lang=js&"
import style0 from "./handle-category.vue?vue&type=style&index=0&id=62ad7f18&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62ad7f18",
  null
  
)

export default component.exports